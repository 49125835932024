// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { IState } from "~stores/index";
import { IHttpReturn, IHttpProps } from "./types";
import { patch } from "./base";

export default (props: IHttpProps): IHttpReturn => {
	const { url = "", initialOptions = { headers: {} } } = props;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [options, setOptions] = useState(initialOptions);
	const [response, setResponse] = useState(undefined);
	// const session = useSelector((state: IState) => state.session);

	const _patch = async (data: any | null, callback: Function | undefined) => {
		try {
			setLoading(true);
			setError(null);

			const req = await patch(url, data, options);

			if (req) {
				setResponse(req.data);

				if (callback) {
					callback(req.data);
				}
			}
		} catch (errors: any) {
			if (errors.response) {
				setError(errors.response.data);
				if (callback) callback(errors.response.data);
			} else {
				setError(errors);
				if (callback) callback(errors);
			}
		} finally {
			setLoading(false);
		}
	};

	// useEffect(() => {
	//   if (session?.access_token && session?.refresh_token) {
	//     setOptions((prevState) => ({
	//       ...prevState,
	//       headers: {
	//         ...prevState.headers,
	//         'X-Access-Token': session.access_token,
	//       },
	//     }));
	//   }
	// }, [session]);

	return { loading, error, response, setOptions, patch: _patch };
};
