import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { IState } from "../../stores";
import { uiSidebarToggle } from "../../stores/ui";
import UserMenu from "./UserMenu";
import RegionMenu from "./RegionMenu";
import LangMenu from "./LangMenu";

import "./Header.less";

const { Header } = Layout;

const HeaderComponent: FC = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state: IState) => state.ui);

  const toggleSidebar = (): void => {
    dispatch(uiSidebarToggle());
  };

  return (
    <Header className="header">
      {React.createElement(ui?.sidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'sidebar-toggle',
        onClick: toggleSidebar,
      })}
      <RegionMenu />
      <UserMenu />
      <LangMenu />
    </Header>
  );
};

export default HeaderComponent;