import React, { FC } from "react";
import { Spin } from "antd";

const Loading: FC = () => (
  <div
    style={{
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Spin size="large" spinning={true} />
  </div>
);

export default Loading;
