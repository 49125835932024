import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Menu } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { sessionReset } from "../../stores/session";

const UserDropdownMenu: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = (): void => {
    dispatch(sessionReset());
    history.push("/");
  };

  const handleMenuClick = ({ key }: any): void => {
    switch (key) {
    case "logout":
      handleLogout();
      break;
    default:
      break;
    }
  };

  return (
    <Menu mode="inline" theme="light" onClick={handleMenuClick}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
        My Profile
        <Link to="/users/profile" />
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(UserDropdownMenu);
