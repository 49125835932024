import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { IState } from "~stores/index";
import { sessionSet, sessionReset } from "~stores/session";
import useInterval from "~hooks/useInterval";

import { useRefresh } from "./auth/api";

import Loading from "~components/Loading";
import Auth from "./auth";
import { MainRoute } from "~components/Layout";
import { setToken } from "~app/utils/token";

const Root: FC = () => {
  const dispatch = useDispatch();
  const session = useSelector((state: IState) => state.session);
  const { post } = useRefresh();

  const handleRefresh = (data: any): void => {
    if (data) {
      if (data.success) {
        dispatch(sessionSet(data.data));
        setToken(data.data.access_token, data.data.refresh_token);
      } else {
        dispatch(sessionReset());
      }
    }
  };

  useInterval(() => {
    if (session?.access_token || session?.refresh_token) {
      post(
        {
          refresh_token: session.refresh_token,
        },
        handleRefresh
      );
    }
  }, 150000);

  useEffect(() => {
    // Session check at component is mounted and
    // session is initialized
    const now = new Date();

    // check remember me
    // if session initialized as remember me, and expiration
    // is not reached, send refresh to server
    if (
      (session.access_token && session.refresh_token) ||
      new Date(session.expires_at) > now
    ) {
      post(
        {
          refresh_token: session.refresh_token,
        },
        handleRefresh
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Suspense fallback={<Loading />}>
      <Router>
        {session?.access_token && session.refresh_token ? (
          <MainRoute />
        ) : (
          <Auth />
        )}
      </Router>
    </React.Suspense>
  );
};

export default Root;
