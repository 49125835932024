import { lazy } from "react";
import {
	StarOutlined,
	SettingOutlined,
	GlobalOutlined,
	ShopOutlined,
	FileProtectOutlined,
	UnorderedListOutlined,
	ShoppingCartOutlined,
	CommentOutlined,
	CreditCardOutlined,
} from "@ant-design/icons";

export interface IRoutes {
	label: string;
	key: string;
	showInMenu?: boolean;
	path?: string;
	icon?: any;
	component?: any;
	exact?: boolean;
	children?: IRoutes[];
}

const Offer = lazy(() => import("~modules/offer"));
const Banner = lazy(() => import("~modules/banners"));
const Promo = lazy(() => import("~modules/promos"));
const Setting = lazy(() => import("~modules/settings"));
const User = lazy(() => import("~modules/user"));
const Shop = lazy(() => import("~modules/shops"));
const Sku = lazy(() => import("~modules/skus"));
const ServiceOption = lazy(() => import("~modules/service-options"));
const Service = lazy(() => import("~modules/services"));
const Supplier = lazy(() => import("~modules/suppliers"));
const Brand = lazy(() => import("~modules/brands"));
const Suggestion = lazy(() => import("~modules/suggestions"));
const PricedOrder = lazy(() => import("~modules/orders/pricedOrder"));
const BundleOrder = lazy(() => import("~modules/orders/bundleOrder"));
const OfferOrder = lazy(() => import("~modules/orders/offerOrder"));
const UnpricedOrder = lazy(() => import("~modules/orders/unpricedOrder"));
const AllBookings = lazy(() => import("~modules/orders/AllBookings"));
const CarOwner = lazy(() => import("~modules/car-owners"));
const Mechanic = lazy(() => import("~modules/mechanics"));
const CarModel = lazy(() => import("~modules/car-models"));
const RatingAutoShop = lazy(() => import("~modules/rating-auto-shops"));
const RatingCarOwner = lazy(() => import("~modules/rating-car-owners"));
const Payment = lazy(() => import("~modules/payments"));
const Refund = lazy(() => import("~modules/refunds"));
const Feedback = lazy(() => import("~modules/feedbacks"));
const Bundles = lazy(() => import("~modules/bundles"));
const Subscription = lazy(() => import("~modules/subscription"));
const Insurance = lazy(() => import("~modules/insurance"));
const InsuranceQuotation = lazy(() => import("~modules/insurance-quotation"));
const Automart = lazy(() => import("~modules/automart"));
const RoadsideServiceProvider = lazy(
	() => import("~modules/roadsideserviceproviders")
);

const routes: IRoutes[] = [
	{
		label: "Following",
		key: "following",
		icon: StarOutlined,
	},
	{
		label: "Bookings",
		key: "bookings",
		icon: ShoppingCartOutlined,
		children: [
			{
				label: "Priced",
				key: "priced-orders",
				path: "/priced-orders",
				exact: false,
				component: PricedOrder,
			},
			{
				label: "Bundles ",
				key: "bundle-orders",
				path: "/bundle-orders",
				exact: false,
				component: BundleOrder,
			},
			{
				label: "Offers",
				key: "offer-orders",
				path: "/offer-orders",
				exact: false,
				component: OfferOrder,
			},
			{
				label: "Unpriced",
				key: "unpriced-orders",
				path: "/unpriced-orders",
				exact: false,
				component: UnpricedOrder,
			},
			{
				label: "All Bookings",
				key: "all-bookings",
				path: "/all-bookings",
				exact: false,
				component: AllBookings,
			},
		],
	},
	{
		label: "Insurance",
		key: "insurance",
		icon: FileProtectOutlined,
		children: [
			{
				label: "Insurance Quotation",
				key: "insurance-quotation",
				path: "/insurance-quotation",
				exact: false,
				component: InsuranceQuotation,
			}
		],
	},
	{
		label: "Purchases",
		key: "purchases",
		icon: ShopOutlined,
		children: [
			{
				label: "Bundles Purchases",
				key: "subscriptions",
				path: "/subscriptions",
				exact: false,
				component: Subscription,
			},
			{
				label: "Insurance Purchases",
				key: "insurance-purchase",
				path: "/insurance",
				exact: false,
				component: Insurance,
			},
			{
				label: "Automart Purchases",
				key: "automart",
				path: "/automart",
				exact: false,
				component: Automart,
			},
		],
	},
	{
		label: "Payments",
		key: "payments",
		icon: CreditCardOutlined,
		children: [
			{
				label: "Payments",
				key: "payments",
				path: "/payments",
				exact: false,
				component: Payment,
			},
			{
				label: "Refunds",
				key: "refunds",
				path: "/refunds",
				exact: false,
				component: Refund,
			},
		],
	},
	{
		label: "Feedback",
		key: "feedback",
		icon: CommentOutlined,
		children: [
			{
				label: "Ratings - Auto Shops",
				key: "rating-auto-shops",
				path: "/rating-auto-shops",
				exact: false,
				component: RatingAutoShop,
			},
			{
				label: "Ratings - Car Owners",
				key: "rating-car-owners",
				path: "/rating-car-owners",
				exact: false,
				component: RatingCarOwner,
			},
			{
				label: "Feedbacks",
				key: "feedbacks",
				path: "/feedbacks",
				exact: false,
				component: Feedback,
			},
		],
	},
	{
		label: "Publish",
		key: "publish",
		icon: GlobalOutlined,
		children: [
			{
				label: "Home Banners",
				key: "banner",
				path: "/banners",
				exact: false,
				component: Banner,
			},
			{
				label: "Keywords",
				key: "suggestions",
				path: "/suggestions",
				exact: false,
				component: Suggestion,
			},
			{
				label: "Offers",
				key: "offer",
				path: "/offers",
				exact: false,
				component: Offer,
			},
			{
				label: "Bundles",
				key: "bundles",
				path: "/bundles",
				exact: false,
				component: Bundles,
			},
			{
				label: "Promos",
				key: "promos",
				path: "/promos",
				exact: false,
				component: Promo,
			},
		],
	},
	{
		label: "Base",
		key: "base",
		icon: ShopOutlined,
		children: [
			{
				label: "Suppliers",
				key: "suppliers",
				path: "/suppliers",
				exact: false,
				component: Supplier,
			},
			{
				label: "Auto shops",
				key: "shops",
				path: "/shops",
				exact: false,
				component: Shop,
			},
			{
				label: "Roadside (Interim)",
				key: "roadsideserviceproviders",
				path: "/roadsideserviceproviders",
				exact: false,
				component: RoadsideServiceProvider,
			},
			{
				label: "Mechanics",
				key: "mechanic",
				path: "/mechanics",
				component: Mechanic,
				exact: false,
			},
			{
				label: "Car Owners",
				key: "car-owner",
				path: "/car-owners",
				component: CarOwner,
				exact: false,
			},
		],
	},
	{
		label: "Dataset",
		key: "dataset",
		icon: UnorderedListOutlined,
		children: [
			{
				label: "Services",
				key: "services",
				path: "/services",
				exact: false,
				component: Service,
			},
			{
				label: "Service Options",
				key: "serviceoptions",
				path: "/service-options",
				exact: false,
				component: ServiceOption,
			},
			{
				label: "SKU",
				key: "skus",
				path: "/skus",
				exact: false,
				component: Sku,
			},
			{
				label: "Brands",
				key: "brands",
				path: "/brands",
				exact: false,
				component: Brand,
			},
			{
				label: "Car Models",
				key: "car-model",
				path: "/car-models",
				exact: false,
				component: CarModel,
			},
		],
	},
	{
		label: "Settings",
		key: "settings",
		icon: SettingOutlined,
		children: [
			{
				label: "Settings",
				key: "settings",
				path: "/settings",
				showInMenu: false,
				exact: false,
				component: Setting,
			},
			{
				label: "Settings",
				key: "admin-right",
				path: "/settings/admin-right",
			},
		],
	},
	{
		label: "User",
		key: "users",
		path: "/users",
		exact: false,
		showInMenu: false,
		component: User,
	},
];

export default routes;
