import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./Login";

const Auth: FC = () => (
  <Switch>
    <Route path="/">
      <Login />
    </Route>
  </Switch>
);

export default Auth;
