import React, { FC, useState } from "react";
import { Alert, Form, Input, Checkbox, Button } from "antd";
import { useDispatch } from "react-redux";
import useLogin from "~app/modules/auth/api/useLogin";
import { sessionSet } from "~stores/session";

import { IUserLogin, defaultUserLogin } from "./types";
import "./Login.less";
import { setToken } from "~app/utils/token";

const layout: object = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const tailLayout: object = {
	wrapperCol: { offset: 8, span: 16 },
};

const countExpireAt = (start: Date, remember: boolean): Date => {
	const expiration = new Date();

	if (remember) {
		// If remember me is chosen, set session expiration in 7 days
		expiration.setDate(start.getDate() + 7);
	} else {
		// If remember me is not chosen, set session expiration in 1 day
		expiration.setDate(start.getDate() + 1);
	}

	return expiration;
};

const Login: FC = () => {
	const { loading, error, post } = useLogin();
	const dispatch = useDispatch();
	const [rememberMe, setRememberMe] = useState<boolean>(false);

	const handleFinish = async (data: any): Promise<void> => {
		if (data.success) {
			const { access_token, refresh_token } = data.data;
			const login_at = new Date();
			const expires_at = countExpireAt(login_at, rememberMe);
			setToken(access_token, refresh_token);

			dispatch(
				sessionSet({
					...data.data,
					access_token,
					refresh_token,
					login_at,
					expires_at,
					remember_me: rememberMe,
				})
			);
		} else console.log("failed");
	};

	const handleSubmit = (values: IUserLogin): void => {
		post(values, handleFinish);
	};

	const handleRememberMe = (e: any) => {
		setRememberMe(e.target.checked);
	};

	return (
		<div className="login-wrapper">
			<h1 className="login-title">Login</h1>
			<div className="login-error">
				{error.error.message && (
					<Alert type="error" message={error.error.message} />
				)}
			</div>
			<Form
				{...layout}
				name="login"
				onFinish={handleSubmit}
				initialValues={defaultUserLogin}
			>
				<Form.Item
					label="Username"
					name="username"
					rules={[{ required: true, message: "Please input your username." }]}
				>
					<Input disabled={loading} />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: "Please input your password." }]}
				>
					<Input.Password disabled={loading} />
				</Form.Item>

				<Form.Item {...tailLayout}>
					<Checkbox
						checked={rememberMe}
						disabled={loading}
						onChange={handleRememberMe}
					>
						<span className="login-remember-text">Remember Me</span>
					</Checkbox>
				</Form.Item>

				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit" loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Login;
