import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Popover } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import classNames from 'classnames';
import { districts } from "~common/data";
import { IState } from "~stores/index";
import { setRegion } from "~stores/ui";

import "./UserMenu.less";

const RegionDropdownMenu: FC = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state: IState) => state.ui);

  const handleMenuClick = ({ key }: any): void => {
    dispatch(setRegion(key));
  };

  return (
    <Menu mode="inline" theme="light" onClick={handleMenuClick}>
      {districts.map((item: any) => (
        <Menu.Item key={item.key}>
          {item[ui.lang]}
        </Menu.Item>
      ))}
    </Menu>
  );
};

const RegionMenu: FC = () => {
  const [visible, setVisible] = useState(false);

  const ui = useSelector((state: IState) => state.ui);

  const handleVisibleChange = (value: boolean): void => {
    setVisible(value);
  };

  const activeRegion: any = districts.filter((item: any) => item.key === ui?.region);

  return (
    <Popover
      content={<RegionDropdownMenu />}
      visible={visible}
      trigger="click"
      onVisibleChange={handleVisibleChange}
    >
      <div className="user-menu">
        <EnvironmentOutlined className={classNames(["user-menu--text", "user-menu--icon"])} />
        <span className="user-menu--text">
          {activeRegion[0][ui.lang]}
        </span>
      </div>
    </Popover>
  );
};

export default RegionMenu;

// export default withRouter(RegionMenu);
