export interface IHttpOption {
  headers?: object;
  params?: object;
}

export interface IHttpProps {
  url: string;
  initialOptions?: IHttpOption;
  initialSearch?: any;
  initialBody?: any;
  autoReload?: boolean;
  initialLoading?: boolean;
  dataKey?: string;
  initialData?: any;
  authenticated?: boolean;
}

export interface IHttpRequestError {
  success?: boolean;
  error?: {
    code?: number;
    debug?: string;
    message?: string;
    stack?: string;
  };
};

export const defaultHttpRequestError: IHttpRequestError = {
  success: false,
  error: {
    message: undefined,
  },
};

export interface IHttpReturn {
  loading: boolean;
  error: any;
  response?: any;
  options?: any;
  setOptions?: any;
  setBody?: any;
  search?: any;
  setSearch?: any;
  get?: any;
  post?: any;
  put?: any;
  patch?: any;
  destroy?: any;
  body?: any;
}
