import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Popover, Avatar } from "antd";
import { IState } from "~stores/index";
import UserDropdownMenu from "./UserDropdownMenu";

import "./UserMenu.less";

const UserMenu: FC = () => {
  const [visible, setVisible] = useState(false);

  const session = useSelector((state: IState) => state.session);

  const handleVisibleChange = (value: boolean): void => {
    setVisible(value);
  };

  return (
    <Popover
      content={<UserDropdownMenu />}
      visible={visible}
      trigger="click"
      onVisibleChange={handleVisibleChange}
    >
      <div className="user-menu">
        <Avatar className={classNames(["user-menu--text", "user-menu--icon"])}>
          {session?.user.display_name?.charAt(0).toUpperCase()}
        </Avatar>
        <span className="user-menu--text">
          {session?.user.display_name}
        </span>
      </div>
    </Popover>
  );
};

export default UserMenu;
