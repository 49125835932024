import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import session, { ISession } from "./session";
import ui, { IUi } from "./ui";

export interface IState {
  session: ISession;
  ui: IUi;
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "ui"],
};

const rootReducer = combineReducers({
  session,
  ui,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());

export default store;
