// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useCallback } from "react";
// import { useSelector } from "react-redux";
// import { IState } from "~stores/index";
import { IHttpProps } from "./types";
import { get } from "./base";

export default (props: IHttpProps): object => {
	const {
		url,
		initialOptions,
		initialLoading = false,
		dataKey = "data",
		initialData = {},
	} = props;
	const [loading, setLoading] = useState(initialLoading);
	const [error, setError] = useState(null);
	const [options, setOptions] = useState(initialOptions);
	// const session = useSelector((state: IState) => state.session);
	const [response, setResponse] = useState({
		[dataKey]: initialData,
	});

	// Wrap fetch function in usecallback to make sure
	// it won't get called when the url and the options
	// are not updated
	const _get = useCallback(async () => {
		try {
			setLoading(true);
			setError(null);

			const req: any = await get(url, options);

			if (req) {
				setResponse((prevState) => ({
					...prevState,
					...req.data,
					[dataKey]: {
						...prevState[dataKey],
						...req.data[dataKey],
					},
				}));
			}
		} catch (errors: any) {
			if (errors.response) {
				setError(errors.response.data);
			} else {
				setError(errors);
			}
		} finally {
			setLoading(false);
		}
	}, [url, options, dataKey]);

	// useEffect(() => {
	//   if (session?.access_token && session?.refresh_token) {
	//     setOptions((prevState) => ({
	//       ...prevState,
	//       headers: {
	//         ...prevState?.headers,
	//         'X-Access-Token': session.access_token,
	//       },
	//     }));
	//   }
	// }, [session]);

	return { loading, error, response, setOptions, get: _get };
};
