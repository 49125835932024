import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";
import Sidebar from "~components/Sidebar";
import Header from "~components/Header";

import routes, { IRoutes } from "~modules/Routes";

import "./MainRoute.less";

const MainRoute: FC = () => (
  <Layout className="wrapper">
    <Sidebar />
    <Layout>
      <Header />
      <Layout>
        <Switch>
          <Redirect exact from="/" to="/priced-orders" />
          {routes.map(function routeMapper(
            route: IRoutes,
            index: number
          ): any | null {
            const {
              key,
              path,
              component: Component,
              exact = true,
              children = [],
            } = route;

            if (children.length > 0) {
              return children.map(routeMapper);
            } else if (Component !== undefined && path !== undefined) {
              return (
                <Route
                  key={`route-${key}-${index}`}
                  exact={exact}
                  path={path}
                  render={() => <Component />}
                />
              );
            }

            return null;
          })}
        </Switch>
      </Layout>
    </Layout>
  </Layout>
);

export default MainRoute;
