export interface IToken {
  accessToken: string | null;
  refreshToken: string | null;
}

export const setToken = (accessToken: string, refreshToken: string): void => {
  localStorage.setItem("SESS:ACCESSTOKEN", accessToken);
  localStorage.setItem("SESS:REFRESHTOKEN", refreshToken);
};

export const getToken = (): IToken => {
  const accessToken: string | null = localStorage.getItem("SESS:ACCESSTOKEN");
  const refreshToken: string | null = localStorage.getItem("SESS:REFRESHTOKEN");

  return {
    accessToken,
    refreshToken,
  };
};
