// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { usePost } from "~hooks/http/index";

export default () => {
  const request = usePost({
    url: '/auth/login',
  });

  return request;
};