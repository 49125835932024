// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useCallback } from "react";
import { IHttpProps, IHttpReturn } from "./types";
import { post } from "./base";

export default (props: IHttpProps): IHttpReturn => {
	const {
		url,
		initialBody,
		autoReload = true,
		initialLoading = false,
		initialSearch = [],
		dataKey = "data",
		// authenticated = true
	} = props;
	const [loading, setLoading] = useState(initialLoading);
	const [error, setError] = useState(null);
	const [body, setBody] = useState(initialBody);
	const [search, setSearch] = useState(initialSearch);
	const [response, setResponse] = useState({
		[dataKey]: [],
		current_page: 0,
		total_pages: 0,
		row_count: 0,
	});

	// Wrap fetch function in usecallback to make sure
	// it won't get called when the url and the options
	// are not updated
	const _post = useCallback(async () => {
		try {
			setLoading(true);
			setError(null);

			const req: any = await post(url, body);

			if (req) {
				setResponse(req.data);
			}
		} catch (errors: any) {
			if (errors.response) {
				setError(errors.response.data);
			} else {
				setError(errors);
			}
		} finally {
			setLoading(false);
		}
	}, [url, body]);

	// A custom search method
	// useEffect(() => {
	//   setBody((prevState: any) => ({
	//     ...prevState,
	//     page: 1,
	//     ...search,
	//   }));
	// }, [search]);

	useEffect(() => {
		// Get data when options is changed
		// when the autoreload option is set to true
		if (autoReload) {
			_post();
		}
	}, [_post, autoReload]);

	return {
		loading,
		error,
		response,
		body,
		setBody,
		search,
		setSearch,
		post: _post,
	};
};
