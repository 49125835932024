// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import usePost from "~hooks/http/usePost";

export default () => {
  const request = usePost({
    url: '/auth/refresh',
  });

  return request;
};