import axios from "axios";
import url from "~hooks/url";
import { getToken } from "~utils/token";

const version = process.env.REACT_APP_API_VERSION;

const apiClient = axios.create({
  baseURL: url(version),
});

apiClient.interceptors.request.use(
  async (config) => {
    const credentials = await getToken();

    let headers = {
      ...config.headers,
    };

    if (credentials.accessToken) {
      headers = {
        ...config.headers,
        'X-Access-Token': credentials.accessToken,
      };
    }

    return {
      ...config,
      headers,
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  async (response) => response,
  async (error) => Promise.reject(error)
);

const { get, post, put, patch, delete: destroy } = apiClient;

export { get, post, put, patch, destroy };
