import React, { FC } from "react";
import { useHistory, Link } from "react-router-dom";
import { Layout, PageHeader } from "antd";
import classNames from "classnames";
import { PageHeaderProps } from "antd/es/page-header";
import { Route } from "antd/es/breadcrumb/Breadcrumb";
import "./ContentWrapper.less";

export interface IRoute extends Route {
  icon?: any;
}

export interface ContentWrapperProps extends PageHeaderProps {
  withPadding?: boolean;
  withBackButton?: boolean;
  transparent?: boolean;
  headerTransparent?: boolean;
  headerExtra?: any;
  pre?: any;
  preTransparent?: boolean;
  post?: any;
  postTransparent?: boolean;
  routes?: IRoute[];
}

const { Content } = Layout;

const _routes: IRoute[] = [
  {
    path: "/",
    breadcrumbName: "Dashboard",
  },
];

const ContentWrapper: FC<ContentWrapperProps> = (props) => {
  const {
    title = "",
    withPadding,
    withBackButton,
    transparent,
    headerTransparent,
    headerExtra,
    pre,
    preTransparent,
    post,
    postTransparent,
    routes = [],
    children,
  } = props;
  const history = useHistory();

  const itemRender = (route: IRoute) => (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );

  return (
    <Layout className="content--wrapper">
      <div className="content--header">
        <PageHeader
          title={title}
          ghost={headerTransparent}
          extra={headerExtra}
          breadcrumb={{ routes: [..._routes, ...routes], itemRender }}
          onBack={withBackButton ? () => history.goBack() : undefined}
        />
      </div>
      {pre && (
        <div
          className={classNames([
            "content--pre",
            !preTransparent ? "content--pre--white" : null,
          ])}
        >
          {pre}
        </div>
      )}
      <Content
        className={classNames([
          "content--container",
          !transparent ? "content--container--white" : null,
          withPadding ? "content--container--with--padding" : null,
        ])}
      >
        {children}
      </Content>
      {post && (
        <div
          className={classNames([
            "content--post",
            !postTransparent ? "content--post--white" : null,
          ])}
        >
          {post}
        </div>
      )}
    </Layout>
  );
};

export default ContentWrapper;
