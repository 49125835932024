import { IActionType } from "~common/types";

// Interface
export interface IUi {
	sidebarCollapsed: boolean;
	region: string;
	lang: string;
	pages: IPage[];
}
export interface IPage {
	tab: string;
	filters: any;
	path: string;
}

// Action Types
export const UI_SIDEBAR_TOGGLE = "UI_SIDEBAR_TOGGLE";
export const UI_SET_REGION = "UI_SET_REGION";
export const UI_SET_LANG = "UI_SET_LANG";
export const UI_SET_PAGE = "UI_SET_PAGE";

// Initial State
export const initialState: IUi = {
	sidebarCollapsed: false,
	region: "hk",
	lang: "en",
	pages: [],
};

// Actions
export const uiSidebarToggle = (): IActionType => ({
	type: UI_SIDEBAR_TOGGLE,
});

export const setRegion = (payload: string): IActionType => ({
	type: UI_SET_REGION,
	payload,
});

export const setLang = (payload: string): IActionType => ({
	type: UI_SET_LANG,
	payload,
});

export const setPage = (payload: IPage): IActionType => ({
	type: UI_SET_PAGE,
	payload,
});

// Reducer
export default (state = initialState, action: IActionType): IUi => {
	const { type, payload } = action;

	switch (type) {
		case UI_SIDEBAR_TOGGLE:
			return {
				...state,
				sidebarCollapsed: !state.sidebarCollapsed,
			};
		case UI_SET_REGION:
			return {
				...state,
				region: payload,
			};
		case UI_SET_LANG:
			return {
				...state,
				lang: payload,
			};
		case UI_SET_PAGE:
			let newPages = [];
			newPages = state.pages?.filter(
				(item: IPage) => item.path !== payload.path
			);
			newPages.push(payload);
			return {
				...state,
				pages: newPages,
			};
		default:
			return state;
	}
};
